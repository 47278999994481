<template>
    <dropdown-menu v-if="dropdown" :clickable="clickable">
        <template #buttonText>
                        <span class="flex items-center gap-2">
                            <img v-if="flags" :src="'/images/flags/' + currentLocale.flag" :alt="currentLocale.title" class="w-6 shadow-small-box rounded">
                            <span v-if="buttonText && textVariant == 'long'">{{ currentLocale.title }}</span>
                            <span v-else-if="buttonText">{{ currentLocale.locale }}</span>
                        </span>
        </template>
        <dropdown-item v-for="(locale, index) in locales" :key="index"
                       :data-locale="(locale as Language).locale"
                       @click="localeChange"
                       class="cursor-pointer hover:bg-gray">
            <div class="flex items-center gap-2">
                <img v-if="flags"
                     :src="'/images/flags/' + (locale as Language).flag"
                     :alt="(locale as Language).title"
                     class="w-6 shadow-small-box rounded">
                <span v-if="textVariant == 'long'">{{ (locale as Language).title }}</span>
                <span v-else>{{ (locale as Language).locale }}</span>
            </div>
        </dropdown-item>
    </dropdown-menu>
    <div v-else>
        <button v-for="(locale, index) in locales"
                :key="index"
                :data-locale="(locale as Language).locale"
                @click="localeChange">
            <img v-if="flags"
                 :src="'/images/flags/' + (locale as Language).flag"
                 :alt="(locale as Language).title"
                 class="w-6 shadow-small-box rounded">
            <span v-if="buttonText && textVariant == 'long'">{{ (locale as Language).title }}</span>
            <span v-else-if="buttonText">{{ (locale as Language).locale }}</span>
        </button>
    </div>
</template>

<script setup lang="ts">

import ConfiguredLocales from "../../common/i18n/_locales";
import DropdownMenu from "../Navigation/DropdownMenu.vue";
import DropdownItem from "../Navigation/DropdownItem.vue";
import {ref} from "vue";
import {i18n} from "../../common/i18n";
import type Language from "../../stores/translation/Language";
import { useTranslationStore } from "@/stores/translation/translationStore";
import {useRouter} from "vue-router";

defineProps({
    locales: {
        type: Array,
        required: false,
        default: function () {
            return ConfiguredLocales;
        }
    },
    dropdown: {
        type: Boolean,
        required: false,
        default: false,
    },
    dropdownRight: {
        type: Boolean,
        required: false,
        default: false,
    },
    dropdownHideCaret: {
        type: Boolean,
        required: false,
        default: false,
    },
    flags: {
        type: Boolean,
        required: false,
        default: false,
    },
    textVariant: {
        type: String,
        required: false,
        default: 'long',
        validator: (value: string) => ['short', 'long'].indexOf(value) !== -1,
    },
    buttonText: {
        type: Boolean,
        required: false,
        default: false,
    },
    clickable: {
        type: Boolean,
        required: true
    }
});

const getLocale = () => ConfiguredLocales.filter((loc) => loc.locale === i18n.locale.value).shift() || ConfiguredLocales[0];
const currentLocale = ref(getLocale());

const translationStore = useTranslationStore();
const router = useRouter();

const localeChange = (e: Event) => {
    if (!(e.target instanceof HTMLElement)) {
        return false;
    }

    let target: HTMLElement | null = e.target;

    while (target !== null && typeof target.dataset.locale === 'undefined') {
        target = target.parentElement;
    }

    if (target === null || typeof target.dataset.locale === 'undefined') {
        return false;
    }

    translationStore.changeLocale(router, target.dataset.locale)
        .then(() => {
            currentLocale.value = getLocale();
        });
}
</script>
