import { defineStore } from 'pinia';
import fetch from '../../common/utils/fetch';
import type RequestObject from '../../routes/RequestObject';
import type RequestedDateInterface from '../../models/RequestedDate';
import { type Router } from 'vue-router';
import type RequestedDateState from './RequestedDateState';
import { RequestedDateRequest } from './RequestedDateRequest';

export const useRequestedDateStore = defineStore('requestedDateStore', {
    state: (): RequestedDateState => ({
        requestedDates: [],
        requestedDate: {} as RequestedDateInterface,
        loading: false,
        totalRequestedDates: 0,
    }),
    actions: {
        getRequestedDates(router: Router, params: RequestObject = {}): Promise<any> {
            this.loading = true;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'booking_requests', query: params }).href)
                    .then((response: any) => {
                        const requestedDates: RequestedDateInterface[] = [];

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach((element) => {
                                if (element['@type'] !== 'BookingRequest') {
                                    return;
                                }
                                requestedDates.push(element);
                            });
                        }

                        this.requestedDates = requestedDates;
                        this.totalRequestedDates = response['hydra:totalItems'] ?? 0;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },

        getRequestedDate(router: Router, params: RequestObject = {}, requestedDateId: number): Promise<any> {
            const requestedDateLoaded = this.requestedDates.find(requestedDateLoaded => requestedDateLoaded.id === requestedDateId)
            if(requestedDateLoaded) {
                this.requestedDate = requestedDateLoaded
                return Promise.resolve(this.requestedDate);
            }

            this.loading = true;
            params.requestedDateId = requestedDateId;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'get_booking_request', query: params }).href)
                    .then((response: any) => {
                        let requestedDate: RequestedDateInterface | null = null;

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach((element) => {
                                if (element['@type'] === 'BookingRequest' && element.id === requestedDateId) {
                                    requestedDate = element;
                                }
                            });
                        }

                        this.requestedDate = requestedDate;
                        this.loading = false;
                        resolve(requestedDate);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },

        updateRequestedDate(
            router: Router,
            requestedDateRequest: RequestedDateRequest,
            requestedDateId: number
        ): Promise<any> {
            this.loading = true;
            const options = { body: JSON.stringify(requestedDateRequest), method: 'PATCH' };

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'booking_request_update', params: { id: requestedDateId } }).href, options)
                    .then((response) => {
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },
    },
});
