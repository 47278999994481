<template>
    <div class="mb-2">
        <span v-if="label" class="block pb-2 font-bold uppercase tracking-wide text-sm">{{ label }}</span>
        <div class="flex items-center">
            <input @input="updateModel" :accept="accept" type="file" class="block grow" :placeholder="placeholder" />
            <div>
                <slot name="button"></slot>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    label: {
        type: String,
        required: false,
        default: '',
    },
    modelValue: {
        type: [File, () => null],
        default: null,
    },
    placeholder: {
        type: String,
        required: false,
    },
    accept: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(['update:modelValue']);

const updateModel = (event: any) => {
    emit('update:modelValue', event.target.files[0]);
};
</script>
