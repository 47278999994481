<template>
    <transition name="fade">
        <div v-if="isOpen" class="fixed z-50 inset-0 overflow-y-auto bg-opacity-75 bg-gray-darkest">
            <div class="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center">
                <!-- overlay -->
                <div @click="$emit('close')" class="fixed inset-0 transition-opacity"></div>

                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl
                sm:my-8 sm:align-middle transform"
                    :class="widthClasses"
                >
                    <div class="rounded-lg px-4 pt-5 pb-4 sm:p-6 sm:pb-4" :class="backgroundColorClass">
                        <div class="sm:flex sm:items-start min-w-full">
                            <div class="sm:text-left min-w-full">
                                <h3 class="flex flex-row justify-between items-center text-lg leading-6 font-bold
                                text-gray-darkest uppercase border-[#00000061] border-b-2 pb-4">
                                    <span v-if="isLoading">{{Translator.trans('loading', 'Loading', 'modal')}}...</span><span v-else>{{ title }}</span>
                                    <span v-if="closeable"
                                          class="text-[#929292] text-[12px] cursor-pointer"
                                          @click="close">
                                        {{Translator.trans('close', 'Close', 'modal')}}
                                        <i class="marso-icon-close"></i>
                                    </span>
                                </h3>
                                <div v-if="isLoading" class="flex justify-center items-center modal-loading p-4">
                                    <slot name="loading"><i class="icon marso-icon-refresh"></i></slot>
                                </div>
                                <div v-else>
                                    <slot></slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import {watchEffect} from "vue";
import { Translator } from "@/common/i18n";

const props = defineProps({
    title: {
        type: String,
        default: "Modal",
    },
    isOpen: {
        type: Boolean,
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    widthClasses: {
        type: String,
        default: 'w-full sm:w-4/5 max-w-full',
    },
    closeable: {
        type: Boolean,
        default: true
    },
    backgroundColorClass: {
        type: String,
        default: 'bg-white'
    }
});
const emit = defineEmits(["close"]);

watchEffect(() => {
    if (props.isOpen) {
        document.body.classList.add('overflow-hidden');
    } else {
        document.body.classList.remove('overflow-hidden')
    }
});

const close = () => emit("close");
</script>

<style lang="scss" scoped>
.modal-loading {
    .icon {
        // tailwind sizes not big enough
        font-size: 10rem;
        animation: spin 3s linear infinite;
        display: block;
    }
}
</style>
