<template>
    <div class="p-4">
        <div class="flex flex-col md:gap-4 md:flex-row">
            <shop-input
                v-model="localContactData.fleetContactPhoneNumber"
                :label="trans('phone_number', 'Phone Number', 'requested_dates')"
                required
                class="my-5"
                border
                :disabled="isDisabledInput"
            />
            <shop-input
                v-model="localContactData.fleetContactEmail"
                :label="trans('email', 'E-mail', 'requested_dates')"
                required
                class="my-5"
                border
                :disabled="isDisabledInput"
            />
        </div>
    </div>
</template>
<script setup lang="ts">
import { trans } from '../common/i18n';
import { ref, onMounted } from 'vue';
import ShopInput from '../components/Form/ShopInput.vue';

const { contactData, translate, disabled } = defineProps(['contactData', 'translate','disabled']);
const localContactData = ref(contactData || {});
const isDisabledInput = ref(false);

onMounted(() => {
  localContactData.value = { ...contactData };
  isDisabledInput.value = disabled
});
</script>
