<template>
    <label :for="id" class="flex flex-col w-full justify-center">
        <span v-if="label" :class="labelClass">{{ label }}</span>
        <div class="relative w-full flex flex-row items-center">
            <textarea
                :id="id"
                :name="name"
                :placeholder="placeholder"
                :autofocus="autofocus"
                :required="required"
                class="block py-2 px-2 w-full h-full placeholder-gray-dark outline-none transition-all duration-300 disabled:bg-gray text-[15px]"
                :class="{
                    border: border,
                    'border-gray-dark': border,
                    'border-opacity-50': border,
                    'focus:border-blue': border,
                    'focus:ring-4': !disableFocusRing,
                    'focus:ring-blue': !disableFocusRing,
                    'focus:ring-opacity-50': !disableFocusRing,
                    'border-green': valid && showValidity,
                    'border-red': !valid && showValidity,
                    'h-[50px]': rows === 1,
                    [textareaExtraClass]: true,
                }"
                :value="modelValue"
                @input="emitModelValue"
                :disabled="disabled"
                :readonly="readonly"
                :maxlength="maxLength"
                :rows="rows"
            >
            </textarea>
        </div>
        <div v-if="showValidity && invalidMessage !== '' && !valid" class="text-red mt-2 text-sm">
            {{ invalidMessage }}
        </div>
    </label>
</template>

<script setup lang="ts">
import randomString from '../../common/utils/randomString';

const props = defineProps({
    name: String,
    placeholder: {
        type: String,
        required: false,
        default: '',
    },
    label: {
        type: String,
        required: false,
        default: '',
    },
    autofocus: Boolean,
    required: Boolean,
    showValidity: {
        type: Boolean,
        default: false,
    },
    valid: {
        type: Boolean,
        default: true,
        required: false,
    },
    invalidMessage: {
        type: String,
        required: false,
        default: '',
    },
    modelValue: {
        type: String,
    },
    disableFocusRing: {
        type: Boolean,
        default: false,
    },
    border: {
        type: Boolean,
        default: false,
    },
    disabled: Boolean,
    readonly: Boolean,
    maxLength: {
        type: Number,
        required: false,
    },
    rows: {
        type: Number,
        default: 2,
    },
    labelClass: {
        type: String,
        default: 'block pb-2 font-bold uppercase tracking-wide text-sm',
    },
    textareaExtraClass: {
        type: String,
        default: '',
    },
});
const emit = defineEmits(['update:modelValue']);

const emitModelValue = (event: Event) => {
    const target = event.target;

    if (target instanceof HTMLTextAreaElement) {
        emit('update:modelValue', target.value);
    }
};

const id: string = randomString(12);
</script>
