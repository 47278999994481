<template>
    <div class="bg-gray p-4">
        <div class="flex flex-col md:gap-4 md:flex-row">
            <shop-input
                v-model="localCustomerData.phoneNumber"
                :label="trans('phone_number', 'Phone Number', 'requested_dates')"
                required
                class="my-5"
                border
                :disabled="isDisabledInput"
            />
            <shop-input
                v-model="localCustomerData.email"
                :label="trans('email', 'E-mail', 'requested_dates')"
                required
                class="my-5"
                border
                :disabled="isDisabledInput"
            />
        </div>
    </div>
</template>
<script setup lang="ts">
import { trans } from '../common/i18n';
import { ref, onMounted } from 'vue';
import ShopInput from '../components/Form/ShopInput.vue';

const { customerData, translate, disabled } = defineProps(['customerData', 'translate', 'disabled']);
const localCustomerData = ref(customerData || {});
const isDisabledInput = ref(false);

onMounted(() => {
  localCustomerData.value = { ...customerData };
  isDisabledInput.value = disabled;
});
</script>
