<template>
    <div class="login-wrapper w-full flex flex-col items-center justify-center">
        <alert
            v-if="errorMessage"
            :message="errorMessage"
            icon
            dismissable
            @dismissed="onAlertDismissed"
        />

        <div class="w-96 max-w-full px-4">
            <div class="flex justify-center">
                <img src="@/images/marso-leasing-logo-fekvo-red.svg" alt="MARSO">
            </div>

            <form @submit.prevent="onLogin">
                <shop-input
                    type="email"
                    name="email"
                    :placeholder="Translator.trans('email', 'Email', 'login')"
                    autofocus
                    required
                    icon="marso-icon-user"
                    class="my-5"
                    v-model="email"
                />

                <shop-input
                    type="password"
                    name="password"
                    :placeholder="Translator.trans('password', 'Password', 'login')"
                    required
                    icon="marso-icon-lock"
                    class="my-5"
                    v-model="password"
                />

                <button type="submit" class="w-full text-white uppercase font-bold text-lg bg-red rounded-md py-2">
                    {{ Translator.trans('sign_in', 'Sign in', 'login') }}
                </button>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { type Ref, ref} from "vue";
import {type RouteLocation, useRoute, useRouter} from "vue-router";
import { ShopInput } from '../../components/Form';
import Alert from '../../components/Alert.vue';
import { Translator } from '../../common/i18n';
import ServerError from "../../common/error/ServerError";
import {useSecurityStore} from "../../stores/security/securityStore";

const route = useRoute();
const router = useRouter();
const securityStore = useSecurityStore();
const email = ref('');
const password = ref('');
const errorMessage: Ref<string> = ref('');

const onLogin = () => {
    securityStore.login(router, email.value, password.value)
        .then(() => {
            const redirect: RouteLocation = router.resolve(
                route.query.redirect ? `${route.query.redirect}` : { name: 'home' }
            );

            if (redirect.name) {
                router.replace({ name: redirect.name });
                return;
            }

            if (redirect.path) {
                router.replace(redirect.path);
                return;

            }

            router.replace(redirect);
        })
        .catch((error: ServerError) => {
            errorMessage.value = error.toString();
        });
};

const onAlertDismissed = () => {
    errorMessage.value = '';
};
</script>
