'use strict';

import { createI18n } from 'vue-i18n';
import { type Locale, type NamedValue } from "@intlify/core-base/dist/core-base.d.ts";
import fetch from './../utils/fetchWrapper';
import datetimeFormats from './_datetimeFormats';
import numberFormats from './_numberFormats';
import { type App } from 'vue';
import type MessageObjectType from './MessageObjectType';
import debounce from 'lodash/debounce';

const FALLBACK_LOCALE = 'en';

export const i18n = createI18n({
    legacy: false,
    locale: document.querySelector('html')?.lang || 'en',
    fallbackLocale: FALLBACK_LOCALE,
    formatFallbackMessages: true,
    // @ts-ignore
    messages: {},//ShopConfig.getTranslations(),
    datetimeFormats,
    numberFormats,
}).global;

export class Translator {
    private static missingMessages: Array<MessageObjectType> = [];
    private static _saving: boolean = false;

    static trans(
        id: string,
        defaultTranslation: string = `${id}`,
        domain: string = 'messages',
        parameters: NamedValue = {},
        locale: string = ''
    ) {
        if (typeof i18n === 'undefined') {
            return id;
        }

        if (locale === '') {
            locale = i18n.locale.value;
        }

        const message = `${domain}.${id.replace(/[\W]+/gi, '_')}`;

        if (!i18n.te(message, locale)) {
            // message id does not defined in this locale, try fallback
            if (!i18n.te(message, FALLBACK_LOCALE)) {
                Translator.collectMessage(id, domain, FALLBACK_LOCALE, defaultTranslation);

                return i18n.rt(defaultTranslation, parameters, { locale: FALLBACK_LOCALE });
            }

            return i18n.t(message, parameters, { locale: FALLBACK_LOCALE });
        }

        return i18n.t(message, parameters, { locale: locale });
    }

    static n(value: number, key: string = 'decimal', locale: Locale = 'hu-HU') {
        if (typeof i18n === 'undefined') {
            return value;
        }

        return i18n.n(value, key, locale);
    }

    static d(value: Date | string | number, key: string, locale: Locale = 'hu-HU') {
        if (typeof i18n === 'undefined') {
            return value;
        }

        return i18n.d(value, key, locale);
    }

    private static collectMessage(
        id: string,
        domain: string = 'messages',
        locale: string | Locale,
        defaultTranslation: string
    ) {
        if (locale === '' && typeof i18n !== 'undefined') {
            locale = FALLBACK_LOCALE;
        }

        Translator.pushMissingMessages([{
            'domain': domain,
                'locale': locale,
                'id': id,
                'defaultTranslation': defaultTranslation
        }]);
    }

    private static pushMissingMessages(missingMessages: Array<MessageObjectType>) {
        missingMessages.forEach(message => {
            const added = Translator.missingMessages.filter((added: MessageObjectType) => added.id === message.id && added.locale === message.locale && added.domain === message.domain).length !== 0;

            if (!added) {
                Translator.missingMessages.push(message);
            }
        });

        Translator.saveMissingMessages();
    }

    private static saveMissingMessages = debounce(() => {
        if (!Translator.missingMessages.length) {
            return;
        }

        if (Translator._saving) {
            return;
        }

        const missings = Translator.missingMessages;

        fetch('/api/save-missing-messages', { method: 'POST', body: JSON.stringify(missings) })
            .then(response => response.json())
            .then(data => {
                Translator.missingMessages = [];
            })
            .catch(e => {
                // failed saving, push back missing messages for later work
                Translator.pushMissingMessages(missings);
            });
    }, 2500)
}

export class VueTranslatorComponent {
    private installed: boolean = false;

    /**
     * Vue.js component install method
     */
    install(app: App): void {
        if (this.installed) {
            return;
        }

        this.installed = true;

        app.provide('Translator', this);

        app.config.globalProperties.$trans = (
            id: string,
            defaultTranslation: string = `${id}`,
            domain: string = 'messages',
            parameters = {},
            locale: string = ''
        ) => {
            return Translator.trans(id, defaultTranslation, domain, parameters, locale);
        };

        // commented out because of TS validation errors
        // referencing calls replaced with Translator.d and Translator.n
        // app.config.globalProperties.$d = (value: number | Date | string, key: string, locale: Locale) => {
        //     return i18n.d(value, key, locale);
        // };

        // app.config.globalProperties.$n = (value: number, key: string, locale: Locale = 'hu-HU') => {
        //     return i18n.n(value, key, locale);
        // };
    }

    trans(
        id: string,
        defaultTranslation: string = `${id}`,
        domain: string = 'messages',
        parameters: NamedValue = {},
        locale: string = ''
    ) {
        return Translator.trans(id, defaultTranslation, domain, parameters, locale);
    }

    n(value: number, key: string, locale: Locale = 'hu-HU') {
        return Translator.n(value, key, locale);
    }

    d(value: Date | string | number, key: string, locale: Locale = 'hu-HU') {
        return Translator.d(value, key, locale);
    }
}

export const trans = Translator.trans;
export const n = Translator.n;
export const d = Translator.d;

export default new VueTranslatorComponent();
