export function isValidEmail(email: string | null | undefined): boolean {

    if (email === null || email === undefined) {
        return false;
    }

    let accentedLetters = [
        'á', 'Á',
        'é', 'É',
        'í', 'Í',
        'ó', 'Ó',
        'ö', 'Ö',
        'ő', 'Ő',
        'ú', 'Ú',
        'ü', 'Ü',
        'ű', 'Ű'
    ];

    let containAccentedLetter = accentedLetters.some((accentedLetter) => {
        return email.includes(accentedLetter);
    });

    if (containAccentedLetter) {
        return false;
    }

    return /\S+@\S+\.\S+/.test(email);
}

export function isBlank(text: string | undefined | null | number): boolean {
    return text === null || text === undefined || text === '';
}
