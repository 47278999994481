import { defineStore } from 'pinia';
import fetch from '../../common/utils/fetch';
import type RequestObject from '../../routes/RequestObject';
import type TyreReplacementDateInterface from '../../models/TyreReplacementDate';
import { type Router } from 'vue-router';
import type TyreReplacementDateState from './TyreReplacementDateState';

export const useTyreReplacementDateStore = defineStore('tyreReplacementDateStore', {
    state: (): TyreReplacementDateState => ({
        tyreReplacementDates: [],
        tyreReplacementDate: {} as  TyreReplacementDateInterface,
        loading: false,
        totalTyreReplacementDates: 0,
    }),
    actions: {
        getTyreReplacementDates(router: Router, params: RequestObject = {}): Promise<any> {
            this.loading = true;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'bookings', query: params }).href)
                    .then((response: any) => {
                        const tyreReplacementDates: TyreReplacementDateInterface[] = [];

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach((element) => {
                                if (element['@type'] !== 'Booking') {
                                    return;
                                }
                                tyreReplacementDates.push(element);
                            });
                        }

                        this.tyreReplacementDates = tyreReplacementDates;
                        this.totalTyreReplacementDates = response['hydra:totalItems'] ?? 0;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },

        getTyreReplacementDate(
            router: Router,
            params: RequestObject = {},
            tyreReplacementDateId: number
        ): Promise<any> {
            const tyreReplacementDateLoaded = this.tyreReplacementDates.find(tyreReplacementDateLoaded => tyreReplacementDateLoaded.id === tyreReplacementDateId)
            if(tyreReplacementDateLoaded) {
                this.tyreReplacementDate = tyreReplacementDateLoaded
                return Promise.resolve(this.tyreReplacementDate);
            }

            this.loading = true;
            params.tyreReplacementDateId = tyreReplacementDateId;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'get_booking', query: params }).href)
                    .then((response: any) => {
                        let tyreReplacementDate: TyreReplacementDateInterface | null = null;

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach((element) => {
                                if (element['@type'] === 'Booking' && element.id === tyreReplacementDateId) {
                                    tyreReplacementDate = element;
                                }
                            });
                        }

                        this.tyreReplacementDate = tyreReplacementDate;
                        this.loading = false;
                        resolve(tyreReplacementDate);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },
    },
});
