<!-- eslint-disable vue/valid-v-slot -->
<template>
    <div class="w-full 2xl:container mx-auto p-4 flex flex-col">
        <responsive-table
            v-model:fields="tyreReplacementDateFields"
            :items="tyreReplacementDateStore.tyreReplacementDates"
            striped
            condensed
            panel
            :isBusy="tyreReplacementDateStore.loading"
            v-model:sortBy="sortBy"
            v-model:sortDesc="sortDesc"
            pagination
            v-model:currentPage="page"
            v-model:itemsPerPage="itemsPerPage"
            :totalItems="tyreReplacementDateStore.totalTyreReplacementDates"
        >
            <template v-slot:cell-car.plateNumber="{ item: tyreReplacementDateData }">
                <div
                    class="text-blue cursor-pointer"
                    @click="openTyreReplacementDateModal(tyreReplacementDateData as TyreReplacementDateInterface)"
                >
                    {{ (tyreReplacementDateData as TyreReplacementDateInterface).car.plateNumber }}
                </div>
            </template>
        </responsive-table>
        <modal
            v-if="tyreReplacementDateStore.tyreReplacementDate && tyreReplacementDateStore.tyreReplacementDate.car"
            :isOpen="plannedFormModalIsOpen"
            :title="tyreReplacementDateStore.tyreReplacementDate.car.plateNumber"
            @close="plannedFormModalIsOpen = false"
        >
            <alert
                :title="trans('save_error', 'Save error', 'tyre_replacement_dates')"
                :message="errorMessage"
                v-if="errorMessage.length"
            />
            <div class="p-4">
                <div class="flex flex-col lg:gap-4 lg:flex-row">
                    <shop-input
                        :label="trans('plate_number', 'Plate Number', 'tyre_replacement_dates')"
                        required
                        v-model="tyreReplacementDateStore.tyreReplacementDate.car.plateNumber"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                    <shop-input
                        :label="trans('renting_company', 'Renting Company', 'tyre_replacement_dates')"
                        required
                        v-model="tyreReplacementDateStore.tyreReplacementDate.car.rentingCompany"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                    <shop-input
                        :label="trans('service', 'Service', 'tyre_replacement_dates')"
                        required
                        v-model="tyreReplacementDateStore.tyreReplacementDate.service"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                </div>
                <div class="flex flex-col lg:gap-4 lg:flex-row">
                    <shop-input
                        :label="trans('date', 'Date', 'tyre_replacement_dates')"
                        required
                        v-model="tyreReplacementDateStore.tyreReplacementDate.date"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                    <shop-input
                        :label="trans('time', 'Time', 'tyre_replacement_dates')"
                        required
                        v-model="tyreReplacementDateStore.tyreReplacementDate.time"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                </div>
                <customer-information
                    :customer-data="tyreReplacementDateStore.tyreReplacementDate"
                    :translate="trans('customer_information', 'Customer information', 'tyre_replacement_dates')"
                    :disabled="true"
                ></customer-information>
            </div>
        </modal>
        <modal
            v-if="tyreReplacementDateStore.tyreReplacementDate && tyreReplacementDateStore.tyreReplacementDate.car"
            :isOpen="acuteFormModalIsOpen"
            :title="tyreReplacementDateStore.tyreReplacementDate.car.plateNumber"
            @close="acuteFormModalIsOpen = false"
        >
            <alert
                :title="trans('save_error', 'Save error', 'tyre_replacement_dates')"
                :message="errorMessage"
                v-if="errorMessage.length"
            />
            <div class="p-4">
                <div class="flex flex-col md:gap-4 md:flex-row">
                    <shop-input
                        :label="trans('plate_number', 'Plate Number', 'tyre_replacement_dates')"
                        required
                        v-model="tyreReplacementDateStore.tyreReplacementDate.car.plateNumber"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                    <shop-input
                        :label="trans('renting_company', 'Renting Company', 'tyre_replacement_dates')"
                        required
                        v-model="tyreReplacementDateStore.tyreReplacementDate.car.rentingCompany"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                </div>
                <textarea-input
                    v-model="tyreReplacementDateStore.tyreReplacementDate.description"
                    :label="trans('description', 'Description', 'tyre_replacement_dates')"
                    :rows="5"
                    class="my-5"
                    border
                    :disabled="true"
                />
                <div v-if="tyreReplacementDateStore.tyreReplacementDate.images.length > 0">
                    <div class="pb-2 font-bold uppercase tracking-wide text-sm">
                        {{ trans('images', 'Images', 'tyre_replacement_dates') }}
                    </div>
                    <div class="flex flex-wrap gap-4">
                        <div
                            class="flex flex-col gap-2"
                            v-for="(image, index) in tyreReplacementDateStore.tyreReplacementDate.images"
                            :key="index"
                        >
                            <a :href="getFullImageUrl(image.id)" target="_blank">
                                <img
                                    :src="getThumbnailUrl(image.id)"
                                    alt="Image"
                                    class="duration-300 ease-in-out hover:opacity-50"
                                />
                            </a>
                            <i
                                class="marso-icon-download cursor-pointer m-auto"
                                @click="downloadFullImage(image.id)"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script setup lang="ts">
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable.vue';
import type FieldDefinitionInterface from '../../components/ResponsiveTable';
import { ref, type ComputedRef, type Ref, computed, watchEffect } from 'vue';
import { trans } from '../../common/i18n';
import { useRouter } from 'vue-router';
import type RequestObject from '@/routes/RequestObject';
import { useToastStore } from '../../stores/toast/toastStore';
import type ServerError from '@/common/error/ServerError';
import { useTyreReplacementDateStore } from '../../stores/tyreReplacementDate/tyreReplacementDateStore';
import type TyreReplacementDateInterface from '@/models/TyreReplacementDate';
import ShopInput from '../../components/Form/ShopInput.vue';
import Modal from '../../components/Modal.vue';
import Alert from '../../components/Alert.vue';
import { TyreReplacementDateTypeEnum } from '@/stores/tyreReplacementDate/TyreReplacementDateTypeEnum';
import TextareaInput from '../../components/Form/TextareaInput.vue';
import CustomerInformation from '../../components/CustomerInformation.vue';

const errorMessage: Ref<string> = ref<string>('');
const router = useRouter();
const tyreReplacementDateStore = useTyreReplacementDateStore();
const sortBy: Ref<string> = ref<string>('id');
const toastStore = useToastStore();
const sortDesc: Ref<boolean> = ref<boolean>(true);
const page: Ref<number> = ref<number>(1);
const itemsPerPage: Ref<number> = ref<number>(25);
const plannedFormModalIsOpen: Ref<boolean> = ref<boolean>(false);
const acuteFormModalIsOpen: Ref<boolean> = ref<boolean>(false);
const selectedTyreReplacementDate: Ref<TyreReplacementDateInterface | null> = ref<TyreReplacementDateInterface | null>(
    null
);
const tyreReplacementDateFields: Ref<FieldDefinitionInterface[]> = ref<FieldDefinitionInterface[]>([
    {
        key: 'bookingCreatedDate',
        label: trans('plate_number', 'Created Date', 'tyre_replacement_dates') ?? 'Created Date',
        value: 'bookingCreatedDate',
        sortable: true,
        filterable: true,
    },
    {
        key: 'car.plateNumber',
        label: trans('plate_number', 'Plate Number', 'tyre_replacement_dates') ?? 'Plate Number',
        value: 'car.plateNumber',
        sortable: true,
        filterable: true,
    },
    {
        key: 'car.rentingCompany',
        label: trans('renting_company', 'Renting Company', 'tyre_replacement_dates') ?? 'Renting Company',
        value: 'car.rentingCompany',
        sortable: true,
        filterable: true,
    },
    {
        key: 'service',
        label: trans('service', 'Service', 'tyre_replacement_dates') ?? 'Service',
        value: 'service',
        sortable: true,
        filterable: true,
    },
    {
        key: 'date',
        label: trans('date', 'Date', 'tyre_replacement_dates') ?? 'Date',
        value: 'date',
        sortable: true,
        filterable: true,
    },
    {
        key: 'time',
        label: trans('time', 'Time', 'tyre_replacement_dates') ?? 'Time',
        value: 'time',
        sortable: true,
        filterable: false,
    },
    {
        key: 'email',
        label: trans('email', 'E-mail', 'tyre_replacement_dates') ?? 'E-mail',
        value: 'email',
        sortable: false,
        filterable: false,
    },
    {
        key: 'phoneNumber',
        label: trans('phone_number', 'Phone Number', 'tyre_replacement_dates') ?? 'Phone Number',
        value: 'phoneNumber',
        sortable: false,
        filterable: false,
    },
    {
        key: 'type',
        label: trans('type', 'Type', 'tyre_replacement_dates') ?? 'Type',
        value: 'type',
        sortable: true,
        filterable: true,
    },
]);

const requestObject: ComputedRef<RequestObject> = computed(() => {
    const filters: { [key: string]: string } = {};
    tyreReplacementDateFields.value.forEach((field: FieldDefinitionInterface) => {
        if (field.filterable && field.filterValue) {
            filters[field.key] = field.filterValue;
        }
    });

    let order = `order[${sortBy.value}]`;

    let requestObject: RequestObject = {
        page: page.value,
        itemsPerPage: itemsPerPage.value,
        [order]: sortDesc.value ? 'desc' : 'asc',
        ...filters,
    };

    return requestObject;
});

const openTyreReplacementDateModal = (tyreReplacementDate: TyreReplacementDateInterface): void => {
    errorMessage.value = '';
    selectedTyreReplacementDate.value = tyreReplacementDate;
    tyreReplacementDateStore.getTyreReplacementDate(router, requestObject.value, selectedTyreReplacementDate.value.id);

    if (tyreReplacementDate.type === TyreReplacementDateTypeEnum.PLANNED) {
        plannedFormModalIsOpen.value = true;
    }
    if (tyreReplacementDate.type === TyreReplacementDateTypeEnum.ACUTE) {
        acuteFormModalIsOpen.value = true;
    }
};

const getThumbnailUrl = (imageId: number) => {
    return router.resolve({ name: 'get_booking_thumbnail', params: { id: imageId } }).href;
};

const getFullImageUrl = (imageId: number) => {
    return router.resolve({ name: 'get_booking_full_image', params: { id: imageId } }).href;
};

const downloadFullImage = (imageId: number) => {
    const link = document.createElement('a');
    link.href = getFullImageUrl(imageId);
    link.download = `image_${imageId}`;
    link.click();
};

watchEffect(() => {
    tyreReplacementDateStore.getTyreReplacementDates(router, requestObject.value).catch((error: ServerError) => {
        toastStore.addErrorToast(error.toString());
    });
});
</script>
