<template>
    <label :for="id" class="flex flex-col w-full justify-center">
        <span v-if="label" class="block pb-2 font-bold uppercase tracking-wide text-sm">{{ label }}</span>
        <div class="relative w-full flex flex-row items-center">
            <span
                v-if="icon"
                class="absolute w-10 flex items-center justify-center"
            >
                <i
                    class="text-lg text-gray-dark"
                    :class="icon"
                ></i>
            </span>
            <input
                :id="id"
                :type="type"
                :name="name"
                :placeholder="placeholder"
                :autofocus="autofocus"
                :required="required"
                class="block py-2 px-2 w-full placeholder-gray-dark text-[15px] outline-none transition-all duration-300
                disabled:bg-gray h-12"
                :class="{
                    'border': border,
                    'border-gray-dark': border,
                    'border-opacity-50': border,
                    'focus:border-blue': border,
                    'pl-10': icon,
                    'focus:ring-4': !disableFocusRing,
                    'focus:ring-blue': !disableFocusRing,
                    'focus:ring-opacity-50': !disableFocusRing,
                    'border-green': valid && showValidity,
                    'border-red': !valid && showValidity
                }"
                :value="modelValue"
                @input="emitModelValue"
                @keyup="$emit('keyup', $event)"
                :disabled="disabled"
                :readonly="readonly"
                :maxlength="maxLength"
                :max="max"
                :pattern="pattern"
                :autocomplete="autocomplete ? 'on' : 'off'"
                :step="step"
            >
        </div>
        <div v-if="showValidity && invalidMessage !== '' && !valid" class="text-red mt-2 text-sm">
                {{invalidMessage}}
        </div>
    </label>
</template>

<script setup lang="ts">
import { ref, type Ref } from "vue";
import randomString from "../../common/utils/randomString";

const props = defineProps({
    type: {
        type: String,
        default: "text",
    },
    name: String,
    placeholder: {
        type: String,
        required: false,
        default: "",
    },
    label: {
        type: String,
        required: false,
        default: "",
    },
    autofocus: Boolean,
    required: Boolean,
    pattern: String,
    showValidity: {
        type: Boolean,
        default: false,
    },
    valid: {
        type: Boolean,
        default: true,
        required: false
    },
    invalidMessage: {
        type: String,
        required: false,
        default: ''
    },
    icon: {
        type: String,
        required: false,
    },
    modelValue: {
        type: [String, Date, Number, () => null],
        default: null
    },
    disableFocusRing: {
        type: Boolean,
        default: false,
    },
    border: {
        type: Boolean,
        default: false,
    },
    disabled: Boolean,
    readonly: Boolean,
    maxLength: {
        type: Number,
        required: false
    },
    max: {
        type: [Number, String],
        required: false
    },
    autocomplete: {
        type: Boolean,
        default: true
    },
    step: {
        type: Number,
        default: 1,
        required: false
    }
});

const emit = defineEmits(["update:modelValue", "keyup"]);

const id: Ref<string> = ref(randomString(12));

const emitModelValue = (event: Event) => {
    const target = event.target;

    if (target instanceof HTMLInputElement) {
        emit(
            'update:modelValue',
            props.type === 'number' ? Number(target.value).valueOf() : target.value
        )
    }
}
</script>
