import TyreReplacementDates from '@/pages/tyreReplacementDates/TyreReplacementDates.vue';

export default [
    {
        name: 'tyre_replacement_dates',
        path: '/tyre-replacement-dates',
        component: TyreReplacementDates,
        meta: { requiresAuth: true, title: 'Tyre Replacement Dates' },
    },
    {
        name: 'bookings',
        path: '/api/bookings',
        redirect: '',
        meta: { requiresAuth: true },
    },
    {
        name: 'get_booking',
        path: '/api/bookings/:id(\\d+)?',
        redirect: '',
        meta: { requiresAuth: true },
    },
    {
        name: 'get_booking_thumbnail',
        path: '/api/booking-images/:id(\\d+)?/thumbnail',
        redirect: '',
        meta: { requiresAuth: true },
    },
    {
        name: 'get_booking_full_image',
        path: '/api/booking-images/:id(\\d+)?/full-image',
        redirect: '',
        meta: { requiresAuth: true },
    },
];