<template>
    <div>
        <template v-if="filterable">
            <button
                class="w-full bg-gray-dark text-white flex items-center justify-between gap-1 py-2 px-4"
                @click="isFiltersClosed = !isFiltersClosed"
            >
                <span class="font-bold">{{ Translator.trans('filters', 'Filters', 'responsive_table') }}</span>
                <i class="marso-icon-chevron-down text-xs transform-gpu transition-transform" :class="{ 'rotate-180': !isFiltersClosed }" aria-hidden="true"></i>
            </button>

            <div class="filters" :class="{ 'closed': isFiltersClosed, 'loading': isBusy }">
                <template v-for="field in visibleFields" :key="field.key">
                    <div class="input-group" v-if="'undefined' !== typeof filters[field.key]">
                        <shop-input
                            :placeholder="field.label"
                            v-model="filters[field.key]"
                            border
                        />
                        <button
                            v-if="filters[field.key]"
                            class="btn btn-rounded btn-secondary btn-outline"
                            @click="filters[field.key] = ''"
                        >
                            {{ Translator.trans('clear', 'Clear', 'responsive_table') }}
                        </button>
                    </div>
                </template>
            </div>
        </template>

        <table
            class="responsive table-auto min-w-full shadow-lg rounded mt-4"
            :class="{
                condensed: condensed,
                striped: striped,
                panel: panel,
                busy: isBusy,
            }"
        >
            <thead class="table-header-group" :class="{[headExtraClasses]: headExtraClasses.length}">
                <tr v-if="slots.header && slots.header().length">
                    <th class="bg-gray-darker text-white border-b-1 border-gray" :colspan="visibleFields.length">
                        <slot name="header"></slot>
                    </th>
                </tr>
                <tr class="hidden lg:table-row" :class="{[headRowExtraClasses]: headRowExtraClasses.length}">
                    <th v-for="field in visibleFields"
                        :key="field.key"
                        class="text-left font-bold bg-gray-darker text-white"
                        :aria-sort="ariaSorts[field.key] ?? null"
                        :class="{ 'cursor-pointer': ariaSorts[field.key] ?? false }"
                        @click="sortBy(field.key)"
                    >
                        <slot :name="`head-${field.key}`">
                            <div class="flex items-center gap-2">
                                <span v-html="field.label"></span>
                                <i class="marso-icon-unsorted text-gray-dark" v-if="ariaSorts[field.key] && ariaSorts[field.key] === 'none'" ></i>
                                <i class="marso-icon-sort-asc" v-else-if="ariaSorts[field.key] && ariaSorts[field.key] === 'ascending'" ></i>
                                <i class="marso-icon-sort-desc" v-else-if="ariaSorts[field.key] && ariaSorts[field.key] === 'descending'" ></i>
                            </div>
                        </slot>
                    </th>
                </tr>
            </thead>
            <tbody class="bg-transparent divide-gray" :class="{'divide-y': !condensed, [bodyExtraClasses]: bodyExtraClasses.length}">
                <template v-for="(item, itemIndex) in items" :key="item">
                    <tr :class="bodyRowExtraClasses"
                        @click="rowClick($event, item)"
                    >
                        <td
                            v-for="field in visibleFields"
                            :key="`${itemIndex}-${field.key}`"
                        >
                            <div
                                class="mobile-label"
                                v-html="field.label"
                            ></div>
                            <slot :name="`cell-${field.key}`" :item="item">
                                <span
                                    v-if="typeof field.value === 'string'"
                                    v-html="cellValueParser(field.key, item)"
                                ></span>
                                <span
                                    v-else-if="typeof field.value === 'function'"
                                    v-html="field.value(item)"
                                ></span>
                            </slot>
                        </td>
                    </tr>

                    <slot name="afterRow" :item="item" :visibleColumnsCount="visibleFields.length"></slot>

                </template>
                <tr v-if="items.length === 0">
                    <td
                        class="text-center font-semibold uppercase tracking-widest"
                        :colspan="visibleFields.length"
                    >
                        <template v-if="isBusy">{{ Translator.trans('loading', 'Loading...', 'responsive_table') }}</template>
                        <template v-else>{{ Translator.trans('there_is_no_data', 'There is no data.', 'responsive_table') }}</template>
                    </td>
                </tr>
            </tbody>

            <tfoot v-if="(slots.footer && slots.footer().length) || pagination">
                <tr v-if="slots.footer && slots.footer().length">
                    <td
                        :colspan="visibleFields.length"
                    >
                        <slot name="footer"></slot>
                    </td>
                </tr>
                <tr v-if="pagination">
                    <td
                        :colspan="visibleFields.length"
                        class="text-center border-t-1 border-gray-dark border-double bg-white"
                    >
                        <pagination
                            :total="totalItems"
                            v-model:current="page"
                            :perPage="itemsPerPage"
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script setup lang="ts">
import debounce from "lodash/debounce";
import {computed, type ComputedRef, type PropType, type Ref, ref, useSlots, watch} from "vue";
import type FieldDefinitionInterface from "./index";
import { isFieldDefinition } from "./index";
import { ShopInput } from "../Form";
import Pagination from "../Pagination.vue";
import { Translator } from "@/common/i18n";

const slots = useSlots();
const props = defineProps({
    isBusy: {
        type: Boolean,
        default: false,
    },
    fields: {
        type: Array as PropType<Array<FieldDefinitionInterface>>,
        required: true,
    },
    items: {
        type: Array,
        required: true,
    },
    striped: Boolean,
    bodyRowExtraClasses: {
        type: String,
        default: "",
    },
    bodyExtraClasses: {
        type: String,
        default: "",
    },
    headExtraClasses: {
        type: String,
        default: "",
    },
    headRowExtraClasses: {
        type: String,
        default: "",
    },
    condensed: Boolean,
    panel: Boolean,
    sortBy: {
        type: String,
        default: (props: any) => props.fields[Object.keys(props.fields)[0]].key,
    },
    sortDesc: Boolean,
    pagination: Boolean,
    totalItems: Number,
    itemsPerPage: {
        type: Number,
        default: 40,
    },
    currentPage: {
        type: Number,
        default: 1,
    },
    storeAction: String,
    needRowClickEmit: Boolean,
});
const emit = defineEmits(['update:sortBy', 'update:sortDesc', 'update:currentPage', 'update:itemsPerPage', 'update:fields', 'click:tableRow']);

const visibleFields: ComputedRef<FieldDefinitionInterface[]> = computed(() => props.fields.filter(field => isFieldDefinition(field) && (typeof field.visible === 'undefined' || field.visible)));
const filterable = computed(() => 'undefined' !== typeof props.fields.find(field => isFieldDefinition(field) && (typeof field.filterable !== 'undefined' || field.filterable)));
const isFiltersClosed = ref(false);
const filters = ref((() => {
        const filters: any = {};
        props.fields.forEach(field => {
            if (isFieldDefinition(field) && typeof field.filterable !== 'undefined' && field.filterable) {
                filters[field.key] = field.filterValue ?? '';
            }
        });
        return filters;
    })());
const ariaSorts = computed(() => {
    const sorts: any = {};
    props.fields.forEach(field => {
        if (isFieldDefinition(field) && typeof field.sortable !== 'undefined' && field.sortable) {
            sorts[field.key] = props.sortBy === field.key ? (props.sortDesc ? 'descending' : 'ascending') : 'none';
        }
    });
    return sorts;
});
const sortBy = (key: string) => {
    if (props.sortBy === key) {
        emit('update:sortDesc', !props.sortDesc);
    } else {
        emit('update:sortBy', key);
        emit('update:sortDesc', false);
    }
};
const page: Ref<number> = ref(props.currentPage);

watch(() => props.currentPage, (newValue: number, oldValue: number) => {
        page.value = newValue;
    });

watch(filters, debounce((newFilters) => {
        for (const key in newFilters) {
            if (Object.hasOwnProperty.call(newFilters, key)) {
                props.fields.forEach(field => {
                    if (isFieldDefinition(field) && field.key === key) {
                        field.filterValue = newFilters[key];
                    }
                });
            }
        }
        emit('update:fields', props.fields);
    }, 300), { deep: true });

watch(page, newPage => {
    emit('update:currentPage', newPage);
});

const rowClick = (event: Event, item: any) => {
    if (props.needRowClickEmit) {
        emit('click:tableRow', event, item);
    }
};

const cellValueParser = (fieldKey: string, item: any): string => {
    return fieldKey
        .split('.') //because of associations/relations e.g.: Users.vue::customer.customerName list field
        .reduce(
            (a, b) => {
                return typeof a[b] !== 'undefined' ? a[b] : ''
            },
            item //initialValue
        );
}
</script>

<style lang="scss">
table.responsive {
    @apply relative border-separate;
    border-spacing: 0 1rem;

    @screen lg {
        &.condensed {
            @apply border-collapse;
            border-spacing: 0;
        }

        &.panel {
            @apply shadow-sm rounded-lg overflow-hidden;
        }
    }

    thead {
        th {
            @apply px-4 py-2;
        }
    }

    tfoot {
        td {
            @apply px-4 py-2;
        }
    }

    tbody {
        tr {
            @apply block lg:table-row mb-4 bg-white;

            @screen lg {
                @apply mb-0;
            }

            td {
                @apply px-4 py-2;

                .mobile-label {
                    @apply block font-bold;

                    @screen lg {
                        @apply hidden;
                    }
                }
            }

            td {
                @apply grid grid-cols-1 border-b border-gray;

                @screen lg {
                    @apply table-cell;
                }
            }
        }
    }

    @screen lg {
        &.condensed {
            tbody {
                td {
                    @apply px-4 py-1;
                }
            }
        }
    }

    &.panel {
        tbody {
            tr {
                @apply shadow-sm rounded-lg overflow-hidden;

                @screen lg {
                    @apply shadow-none rounded-none;
                }
            }
        }
    }

    &.striped {
        tbody {
            tr {
                @apply even:bg-gray;
            }
        }
    }

    &.striped.panel {
        tbody {
            tr {
                @apply bg-white;

                @screen lg {
                    @apply even:bg-gray;
                }
            }
        }
    }

    &.busy::after {
        @apply block absolute top-0 left-0 w-full h-full bg-gray-dark opacity-50;
        content: " ";
    }
}
</style>
