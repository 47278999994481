<template>
    <div>
        <!-- Desktop menu -->
        <div class="fade-item hidden lg:block" key="nav-desktop">
            <div class="h-14 fixed z-50 top-0 flex items-stretch w-full bg-red shadow">
                <div class="w-full 2xl:container px-4 mx-auto flex flex-wrap items-stretch justify-between">
                    <div class="h-full relative flex gap-4 justify-start items-stretch">
                        <nav-menu class="uppercase font-bold text-sm" dropdown-arrow>
                            <nav-menu-item :to="{ name: 'home' }" class="mr-4 whitespace-nowrap self-center">
                                <img src="@/images/marso-leasing-logo-fekvo.svg" alt="MARSO logo" />
                            </nav-menu-item>
                        </nav-menu>
                        <nav-menu class="text-white uppercase font-bold text-sm">
                            <nav-menu-item :to="{ name: 'home' }">
                                {{ trans('car data maintenance', 'Car Data Maintenance', 'navbar') }}
                            </nav-menu-item>
                            <dropdown-menu
                                clickable
                                class="text-white"
                                :button-text="trans('query_of_administrations', 'Query of Administrations', 'navbar')"
                                right
                            >
                                <dropdown-item :to="{ name: 'requested_dates' }">
                                    {{ trans('requested_dates', 'Requested Dates', 'navbar') }}
                                </dropdown-item>
                                <dropdown-item :to="{ name: 'tyre_replacement_dates' }">
                                    {{
                                        trans(
                                            'dates_booked_for_tyre_replacement',
                                            'Dates Booked for Tyre Replacement',
                                            'navbar'
                                        )
                                    }}
                                </dropdown-item>
                            </dropdown-menu>
                            <dropdown-menu
                                clickable
                                v-if="administrationMenuVisible"
                                class="text-white"
                                :button-text="trans('administration', 'Administration', 'navbar')"
                                right
                            >
                                <dropdown-item
                                    v-if="currentUser?.hasPermission(UserPermissionEnum.USER_WRITE)"
                                    :to="{ name: 'admin_users' }"
                                >
                                    {{ trans('users', 'Users', 'navbar') }}
                                </dropdown-item>
                                <dropdown-item
                                    v-if="currentUser?.hasPermission(UserGroupPermissionEnum.USER_GROUP_WRITE)"
                                    :to="{ name: 'admin_user_groups' }"
                                >
                                    {{ trans('user_groups', 'User Groups', 'navbar') }}
                                </dropdown-item>
                                <dropdown-divider />
                                <dropdown-item
                                    v-if="currentUser?.hasPermission(LogPermissionEnum.LOG_WRITE)"
                                    :to="{ name: 'admin_logs' }"
                                >
                                    {{ trans('logs', 'Logs', 'navbar') }}
                                </dropdown-item>
                                <dropdown-divider />
                                <dropdown-item
                                    v-if="currentUser?.hasPermission(TranslationPermissionEnum.TRANSLATION_WRITE)"
                                    :to="{ name: 'admin_translations' }"
                                >
                                    {{ trans('translations', 'Translations', 'navbar') }}
                                </dropdown-item>
                            </dropdown-menu>
                        </nav-menu>
                    </div>
                    <nav-menu class="text-white font-bold text-sm">
                        <dropdown-menu right clickable>
                            <template #buttonText>
                                <span class="flex items-center gap-2">
                                    <i class="marso-icon-account text-2xl"></i>
                                    <div class="flex flex-col">
                                        <span>{{ securityStore.authenticatedUser?.email }}</span>
                                    </div>
                                </span>
                            </template>
                            <template #default="slotProps">
                                <dropdown-item @click="onLogout" pointer @selected="slotProps.itemSelected">{{
                                    trans('logout', 'Logout', 'navbar')
                                }}</dropdown-item>
                            </template>
                        </dropdown-menu>
                        <locale-switcher dropdown right flags clickable />
                    </nav-menu>
                </div>
            </div>
        </div>

        <!-- Mobile menu -->
        <div class="fade-item block lg:hidden" key="nav-mobile">
            <div class="navbar fixed top-0 z-50 flex items-stretch w-full bg-red shadow">
                <div class="relative w-full px-4 flex items-stretch justify-between">
                    <nav-menu class="uppercase font-bold text-sm">
                        <nav-menu-item :to="{ name: 'home' }" class="mr-4 whitespace-nowrap">
                            <img src="@/images/marso-leasing-logo-fekvo.svg" alt="MARSO logo" />
                        </nav-menu-item>
                    </nav-menu>

                    <nav-menu class="font-bold uppercase text-white text-xs">
                        <dropdown-menu clickable hideCaret right mobileFullWidth>
                            <template #buttonText>
                                <i class="marso-icon-account text-2xl py-2"></i>
                            </template>
                            <dropdown-item>
                                <div class="flex flex-col">
                                    <span>{{ securityStore.authenticatedUser?.email }}</span>
                                </div>
                            </dropdown-item>
                            <dropdown-divider />
                            <dropdown-item @click="onLogout">{{ trans('logout', 'Logout', 'navbar') }}</dropdown-item>
                        </dropdown-menu>

                        <!-- <locale-switcher dropdown flags hideCaret right mobileFullWidth clickable/> -->
                        <locale-switcher dropdown flags hideCaret right mobileFullWidth clickable />

                        <dropdown-menu ref="rootMobileMenu" clickable hideCaret right mobileFullWidth>
                            <template #buttonText>
                                <i class="marso-icon-hamburger_menu text-2xl"></i>
                            </template>

                            <!-- Administration menu mindig utolsó legyen -->
                            <dropdown-menu
                                v-slot="slotProps"
                                @selected="onMobileMenuItemSelected"
                                :clickable="true"
                                v-if="administrationMenuVisible"
                                :button-text="trans('administration', 'Administration', 'navbar')"
                                right
                            >
                                <dropdown-item
                                    v-if="currentUser?.hasPermission(UserPermissionEnum.USER_WRITE)"
                                    @selected="slotProps.itemSelected"
                                    :to="{ name: 'admin_users' }"
                                >
                                    {{ trans('users', 'Users', 'navbar') }}
                                </dropdown-item>
                                <dropdown-item
                                    v-if="currentUser?.hasPermission(UserGroupPermissionEnum.USER_GROUP_WRITE)"
                                    @selected="slotProps.itemSelected"
                                    :to="{ name: 'admin_user_groups' }"
                                >
                                    {{ trans('user_groups', 'User Groups', 'navbar') }}
                                </dropdown-item>
                                <dropdown-divider />
                                <dropdown-item
                                    v-if="currentUser?.hasPermission(LogPermissionEnum.LOG_WRITE)"
                                    @selected="slotProps.itemSelected"
                                    :to="{ name: 'admin_logs' }"
                                >
                                    {{ trans('logs', 'Logs', 'navbar') }}
                                </dropdown-item>
                                <dropdown-divider />
                                <dropdown-item
                                    v-if="currentUser?.hasPermission(TranslationPermissionEnum.TRANSLATION_WRITE)"
                                    @selected="slotProps.itemSelected"
                                    :to="{ name: 'admin_translations' }"
                                >
                                    {{ trans('translations', 'Translations', 'navbar') }}
                                </dropdown-item>
                            </dropdown-menu>
                            <nav-menu-item :to="{ name: 'home' }" @selected="onMobileMenuItemSelected" class="p-2"
                                >{{ trans('car data maintenance', 'Car data maintenance', 'navbar') }}
                            </nav-menu-item>
                            <dropdown-menu
                                clickable
                                :button-text="trans('query_of_administrations', 'Query of Administrations', 'navbar')"
                                right
                            >
                                <dropdown-item :to="{ name: 'requested_dates' }">
                                    {{ trans('requested_dates', 'Requested Dates', 'navbar') }}
                                </dropdown-item>
                                <dropdown-item :to="{ name: 'tyre_replacement_dates' }">
                                    {{
                                        trans(
                                            'dates_booked_for_tyre_replacement',
                                            'Dates Booked for Tyre Replacement',
                                            'navbar'
                                        )
                                    }}
                                </dropdown-item>
                            </dropdown-menu>
                        </dropdown-menu>
                    </nav-menu>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef, type Ref, ref } from 'vue';
import NavMenu from '../Navigation/NavMenu.vue';
import NavMenuItem from '../Navigation/NavMenuItem.vue';
import DropdownDivider from '../Navigation/DropdownDivider.vue';
import DropdownItem from '../Navigation/DropdownItem.vue';
import DropdownMenu from '../Navigation/DropdownMenu.vue';
import LocaleSwitcher from './LocaleSwitcher.vue';
import { trans } from '../../common/i18n';
import {
    UserPermissionEnum,
    UserGroupPermissionEnum,
    LogPermissionEnum,
    TranslationPermissionEnum,
} from '../../stores/permission/PermissionEnum';
import ServerError from '../../common/error/ServerError';
import { useToastStore } from '../../stores/toast/toastStore';
import AuthenticatedUser from '../../stores/security/AuthenticatedUser';
import { useSecurityStore } from '../../stores/security/securityStore';
import { useRouter } from 'vue-router';

const router = useRouter();
const toastStore = useToastStore();
const securityStore = useSecurityStore();
const currentUser: ComputedRef<AuthenticatedUser | null> = computed(() => {
    return securityStore.authenticatedUser;
});
const administrationMenuVisible: ComputedRef<boolean> = computed(() => {
    if (currentUser.value === null) {
        return false;
    }

    let userPermissions: string[] = currentUser.value?.permissions ?? [];

    let administrationMenuPermissions: string[] = [
        UserPermissionEnum.USER_WRITE,
        UserGroupPermissionEnum.USER_GROUP_WRITE,
        LogPermissionEnum.LOG_WRITE,
        TranslationPermissionEnum.TRANSLATION_WRITE,
    ];

    let permissionsIntersection = userPermissions.filter((permission: string) =>
        administrationMenuPermissions.includes(permission)
    );

    return permissionsIntersection.length > 0;
});

const onLogout = (e: Event) => {
    e.preventDefault();
    securityStore.logout(router).catch((error: ServerError) => {
        toastStore.addErrorToast(error.toString());
    });
};

const rootMobileMenu: Ref<typeof DropdownMenu | null> = ref(null);

const onMobileMenuItemSelected = () => {
    rootMobileMenu.value?.close();
};
</script>
