<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <label
        class="toggle flex gap-1 cursor-pointer justify-start items-center"
        :class="{ disabled: disabled }"
        @click="onToggleClicked"
    >
    <span v-if="label.length" v-html="label"></span>
        <slot v-else name="label"></slot>
        <div
            class="w-14 h-8 lg:w-8 lg:h-4 flex items-center rounded-full p-1 duration-150 cursor-pointer"
            :class="{
                'bg-gray-dark': !isChecked,
                'bg-green': isChecked,
                'bg-red': !valid,
                'order-first': reversed}"
            :aria-checked="isChecked"
        >
            <div
                class="bg-white w-6 h-6 lg:w-2 lg:h-2 rounded-full shadow-md transform-gpu duration-150"
                :class="{ 'translate-x-6': isChecked, 'lg:translate-x-4': isChecked }"
            ></div>
        </div>
    </label>
</template>

<script setup lang="ts">
import {type Ref, ref, watch} from "vue";

const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    modelValue: [Boolean, Number, String],
    checked: Boolean,
    disabled: Boolean,
    reversed: Boolean,
    valid: {
        type: Boolean,
        default: true,
        required: false
    },
    showValidity: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(["update:modelValue", "change"]);

const isChecked: Ref<boolean> = ref(props.checked || (Boolean(props.modelValue).valueOf() ?? false));
const onToggleClicked = () => {
    if (props.disabled) {
        return;
    }
    isChecked.value = !isChecked.value;
    emit('update:modelValue', isChecked.value);
    emit('change', isChecked.value);
}

// update isChecked when modelValue updated from the calling component
watch(
    () => props.modelValue,
    val => {
        isChecked.value = Boolean(val).valueOf() ?? false;
    }
)
</script>

<style lang="scss">
label.toggle {
    @apply relative;

    &.disabled::after {
        @apply block absolute left-0 top-0 w-full h-full;

        content: " ";
    }
}
</style>

