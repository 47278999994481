import CarDataMaintenance from '@/pages/carDataMaintenance/CarDataMaintenance.vue';

export default [
    {
        name: 'home',
        path: '/car-data-maintenance',
        component: CarDataMaintenance,
        meta: { requiresAuth: true, title: 'Car Data Maintenance' },
    },
    {
        name: 'cars',
        path: '/api/cars',
        redirect: '',
        meta: { requiresAuth: true },
    },
    {
        name: 'car_update',
        path: '/api/cars/:id(\\d+)?',
        redirect: '',
        meta: { requiresAuth: true },
    },
    {
        name: 'upload_cars_csv',
        path: '/api/cars/upload-csv',
        redirect: '',
        meta: { requiresAuth: true },
    },
];
