import { defineStore } from 'pinia';
import fetch from '../../common/utils/fetch';
import type RequestObject from '../../routes/RequestObject';
import type CarInterface from '../../models/Car';
import { type Router } from 'vue-router';
import type CarState from './CarState';
import type { CarRequest } from './CarRequest';

export const useCarStore = defineStore('carStore', {
    state: (): CarState => ({
        cars: [],
        loading: false,
        totalCars: 0,
    }),
    actions: {
        getCars(router: Router, params: RequestObject = {}): Promise<any> {
            this.loading = true;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'cars', query: params }).href)
                    .then((response: any) => {
                        const cars: CarInterface[] = [];

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach((element) => {
                                if (element['@type'] !== 'Car') {
                                    return;
                                }
                                cars.push(element);
                            });
                        }

                        this.cars = cars;
                        this.totalCars = response['hydra:totalItems'] ?? 0;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },

        createCar(router: Router, couponRequest: CarRequest): Promise<any> {
            this.loading = true;
            const options = { body: JSON.stringify(couponRequest), method: 'POST' };

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'cars' }).href, options)
                    .then((response) => {
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },

        updateCar(router: Router, carRequest: CarRequest, carId: number): Promise<any> {
            this.loading = true;
            const options = { body: JSON.stringify(carRequest), method: 'PATCH' };

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'car_update', params: { id: carId } }).href, options)
                    .then((response) => {
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },

        uploadCarCsv(router: Router, formData: FormData): Promise<any> {
            this.loading = true;

            const options = {
                method: 'POST',
                body: formData,
            };

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'upload_cars_csv' }).href, options)
                    .then((response) => {
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },
    },
});
