<!-- eslint-disable vue/valid-v-slot -->
<template>
    <div class="w-full 2xl:container mx-auto p-4 flex flex-col">
        <responsive-table
            v-model:fields="requestedDateFields"
            :items="requestedDateStore.requestedDates"
            striped
            condensed
            panel
            :isBusy="requestedDateStore.loading"
            v-model:sortBy="sortBy"
            v-model:sortDesc="sortDesc"
            pagination
            v-model:currentPage="page"
            v-model:itemsPerPage="itemsPerPage"
            :totalItems="requestedDateStore.totalRequestedDates"
        >
            <template v-slot:cell-car.plateNumber="{ item: requestedDateData }">
                <div
                    class="text-blue cursor-pointer"
                    @click="openRequestedDateModal(requestedDateData as RequestedDateInterface)"
                >
                    {{ (requestedDateData as RequestedDateInterface).car.plateNumber }}
                </div>
            </template>
            <template #cell-type="{ item: requestedDateData }">
                <div>
                    {{
                        (requestedDateData as RequestedDateInterface).type === RequestedDateTypeEnum.DAMAGE_EVENT
                            ? trans('damage_event', 'DAMAGE EVENT', 'requested_dates')
                            : trans('service_request', 'SERVICE REQUEST', 'requested_dates')
                    }}
                </div>
            </template>
        </responsive-table>
        <modal
            v-if="requestedDateStore.requestedDate && requestedDateStore.requestedDate.car"
            :isOpen="serviceRequestFormModalIsOpen"
            :title="requestedDateStore.requestedDate.car.plateNumber"
            @close="serviceRequestFormModalIsOpen = false"
        >
            <alert
                :title="trans('save_error', 'Save error', 'requested_dates')"
                :message="errorMessage"
                v-if="errorMessage.length"
            />
            <div class="p-4">
                <div class="flex flex-col md:gap-4 md:flex-row">
                    <shop-input
                        :label="trans('plate_number', 'Plate Number', 'requested_dates')"
                        required
                        v-model="requestedDateStore.requestedDate.car.plateNumber"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                    <shop-input
                        :label="trans('type', 'Type', 'requested_dates')"
                        required
                        v-model="requestedDateStore.requestedDate.type"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                </div>
                <div class="flex flex-col md:gap-4 md:flex-row">
                    <shop-input
                        :label="trans('requested_date_from', 'Requested Date From', 'requested_dates')"
                        required
                        v-model="requestedDateStore.requestedDate.requestedDateFrom"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                    <shop-input
                        :label="trans('requested_date_to', 'Requested Date To', 'requested_dates')"
                        required
                        v-model="requestedDateStore.requestedDate.requestedDateTo"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                </div>
                <div class="flex flex-col md:gap-4 lg:flex-row">
                    <div class="flex flex-col md:gap-4 md:flex-row w-full">
                        <shop-input
                            :label="trans('service_site', 'Service Site', 'requested_dates')"
                            required
                            v-model="requestedDateRequest.serviceSite"
                            class="my-5"
                            border
                        />
                        <shop-input
                            type="date"
                            :label="trans('fixed_date', 'Fixed Date', 'requested_dates')"
                            required
                            v-model="requestedDateRequest.fixedDate"
                            class="my-5"
                            border
                        />
                    </div>
                    <div class="flex flex-col md:gap-4 md:flex-row w-full">
                        <shop-input
                            :label="trans('fixed_time', 'Fixed Time', 'requested_dates')"
                            required
                            v-model="requestedDateRequest.fixedTime"
                            class="my-5"
                            border
                        />
                        <double-row-toggle
                            :checked="isChecked"
                            v-model="isChecked"
                            :label="trans('close', 'Close', 'requested_dates')"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                    <shop-input
                        :label="trans('mileage', 'Mileage', 'requested_dates')"
                        required
                        v-model="requestedDateStore.requestedDate.mileage"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                    <double-row-toggle
                        :label="trans('mandatory_service', 'Mandatory Service', 'requested_dates')"
                        v-model="requestedDateStore.requestedDate.mandatoryService"
                        :disabled="true"
                    />
                </div>
                <textarea-input
                    v-model="requestedDateStore.requestedDate.description"
                    :label="trans('description', 'Description', 'requested_dates')"
                    :rows="5"
                    class="my-5"
                    border
                    :disabled="true"
                />
                <div v-if="requestedDateStore.requestedDate.images.length > 0">
                    <div class="pb-2 font-bold uppercase tracking-wide text-sm">
                        {{ trans('images', 'Images', 'requested_dates') }}
                    </div>
                    <div class="flex flex-wrap gap-4">
                        <div
                            class="flex flex-col gap-2"
                            v-for="(image, index) in requestedDateStore.requestedDate.images"
                            :key="index"
                        >
                            <a :href="getFullImageUrl(image.id)" target="_blank">
                                <img
                                    :src="getThumbnailUrl(image.id)"
                                    alt="Image"
                                    class="duration-300 ease-in-out hover:opacity-50"
                                />
                            </a>
                            <i
                                class="marso-icon-download cursor-pointer m-auto"
                                @click="downloadFullImage(image.id)"
                            ></i>
                        </div>
                    </div>
                </div>
                <div class="my-5">
                    <div class="pb-2 font-bold uppercase tracking-wide text-sm">{{ trans('customer_information', 'Customer information', 'requested_dates') }}</div>
                    <customer-information
                        :customer-data="requestedDateStore.requestedDate.car"
                        :disabled="true"
                    ></customer-information>
                </div>
                <div class="my-5">
                    <div class="pb-2 font-bold uppercase tracking-wide text-sm">{{ trans('contact_information', 'Contact information', 'requested_dates') }}</div>
                    <contact-information
                        :contact-data="requestedDateStore.requestedDate.car"
                        :disabled="true"
                    ></contact-information>
                </div>
                <div class="pt-2 border-t-1 border-gray flex justify-end">
                    <button class="btn btn-rounded btn-success" @click="updateRequestedDate()">
                        <i class="marso-icon-check"></i>
                        {{ trans('save', 'Save', 'requested_dates') }}
                    </button>
                </div>
            </div>

        </modal>
        <modal
            v-if="requestedDateStore.requestedDate && requestedDateStore.requestedDate.car"
            :isOpen="damageEventFormModalIsOpen"
            :title="requestedDateStore.requestedDate.car?.plateNumber"
            @close="damageEventFormModalIsOpen = false"
        >
            <alert
                :title="trans('save_error', 'Save error', 'requested_dates')"
                :message="errorMessage"
                v-if="errorMessage.length"
            />

            <div class="p-4">
                <div class="flex flex-col md:gap-4 md:flex-row">
                    <shop-input
                        :label="trans('plate_number', 'Plate Number', 'requested_dates')"
                        required
                        v-model="requestedDateStore.requestedDate.car.plateNumber"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                    <shop-input
                        :label="trans('type', 'Type', 'requested_dates')"
                        required
                        v-model="requestedDateStore.requestedDate.type"
                        class="my-5"
                        border
                        :disabled="true"
                    />
                </div>
                <textarea-input
                    v-model="requestedDateStore.requestedDate.description"
                    :label="trans('description', 'Description', 'requested_dates')"
                    :rows="5"
                    class="my-5"
                    border
                    :disabled="true"
                />
                <div v-if="requestedDateStore.requestedDate.images.length > 0">
                    <div class="pb-2 font-bold uppercase tracking-wide text-sm">
                        {{ trans('images', 'Images', 'requested_dates') }}
                    </div>
                    <div class="flex flex-wrap gap-4">
                        <div
                            class="flex flex-col gap-2"
                            v-for="(image, index) in requestedDateStore.requestedDate.images"
                            :key="index"
                        >
                            <a :href="getFullImageUrl(image.id)" target="_blank">
                                <img
                                    :src="getThumbnailUrl(image.id)"
                                    alt="Image"
                                    class="duration-300 ease-in-out hover:opacity-50"
                                />
                            </a>
                            <i
                                class="marso-icon-download cursor-pointer m-auto"
                                @click="downloadFullImage(image.id)"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
            <customer-information
                :customer-data="requestedDateStore.requestedDate.car"
                :translate="trans('customer_information', 'Customer information', 'requested_dates')"
                :disabled="true"
            ></customer-information>
            <contact-information
                :contact-data="requestedDateStore.requestedDate.car"
                :translate="trans('contact_information', 'Contact information', 'requested_dates')"
                :disabled="true"
            ></contact-information>
        </modal>
    </div>
</template>
<script setup lang="ts">
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable.vue';
import type FieldDefinitionInterface from '../../components/ResponsiveTable';
import { ref, type ComputedRef, type Ref, computed, watchEffect } from 'vue';
import { trans } from '../../common/i18n';
import { useRouter } from 'vue-router';
import type RequestObject from '@/routes/RequestObject';
import { useToastStore } from '../../stores/toast/toastStore';
import type ServerError from '@/common/error/ServerError';
import { useRequestedDateStore } from '../../stores/requestedDate/requestedDateStore';
import type RequestedDateInterface from '@/models/RequestedDate';
import { RequestedDateRequest } from '@/stores/requestedDate/RequestedDateRequest';
import ShopInput from '../../components/Form/ShopInput.vue';
import Modal from '../../components/Modal.vue';
import Alert from '../../components/Alert.vue';
import { RequestedDateTypeEnum } from '@/stores/requestedDate/RequestedDateTypeEnum';
import { DoubleRowToggle } from '../../components/Form';
import TextareaInput from '../../components/Form/TextareaInput.vue';
import { RequestedDateStatusEnum } from '@/stores/requestedDate/RequestedDateStatusEnum';
import { ToastTypeEnum } from '@/stores/toast/ToastInterface';
import CustomerInformation from '../../components/CustomerInformation.vue';
import ContactInformation from '../../components/ContactInformation.vue';

const isChecked: Ref<boolean> = ref(false);
const router = useRouter();
const requestedDateStore = useRequestedDateStore();
const sortBy: Ref<string> = ref<string>('status');
const toastStore = useToastStore();
const sortDesc: Ref<boolean> = ref<boolean>(true);
const page: Ref<number> = ref<number>(1);
const itemsPerPage: Ref<number> = ref<number>(25);
const selectedRequestedDate: Ref<RequestedDateInterface | null> = ref<RequestedDateInterface | null>(null);
const requestedDateRequest: Ref<RequestedDateRequest> = ref(new RequestedDateRequest());
const serviceRequestFormModalIsOpen: Ref<boolean> = ref<boolean>(false);
const damageEventFormModalIsOpen: Ref<boolean> = ref<boolean>(false);
const errorMessage: Ref<string> = ref<string>('');
const requestedDateFields: Ref<FieldDefinitionInterface[]> = ref<FieldDefinitionInterface[]>([
    {
        key: 'requestCreatedDate',
        label: trans('request_created_date', 'Created Date', 'requested_dates') ?? 'Created Date',
        value: 'requestCreatedDate',
        sortable: true,
        filterable: true,
    },
    {
        key: 'car.plateNumber',
        label: trans('plate_number', 'Plate Number', 'requested_dates') ?? 'Plate Number',
        value: 'car.plateNumber',
        sortable: true,
        filterable: true,
    },
    {
        key: 'car.rentingCompany',
        label: trans('renting_company', 'Renting Company', 'requested_dates') ?? 'Renting Company',
        value: 'car.rentingCompany',
        sortable: true,
        filterable: true,
    },
    {
        key: 'requestedDateFrom',
        label: trans('requested_date_from', 'Requested Date From', 'requested_dates') ?? 'Requested Date From',
        value: 'requestedDateFrom',
        sortable: true,
        filterable: true,
    },
    {
        key: 'requestedDateTo',
        label: trans('requested_date_to', 'Requested Date To', 'requested_dates') ?? 'Requested Date To',
        value: 'requestedDateTo',
        sortable: true,
        filterable: true,
    },
    {
        key: 'fixedDate',
        label: trans('fixed_date', 'Fixed Date', 'requested_dates') ?? 'Fixed Date',
        value: 'fixedDate',
        sortable: true,
        filterable: false,
    },
    {
        key: 'type',
        label: trans('type', 'Type', 'requested_dates') ?? 'Type',
        value: 'type',
        sortable: true,
        filterable: true,
    },
    {
        key: 'status',
        label: trans('status', 'Status', 'requested_dates') ?? 'Status',
        value: 'status',
        sortable: true,
        filterable: true,
    },
]);

const requestObject: ComputedRef<RequestObject> = computed(() => {
    const filters: { [key: string]: string } = {};
    requestedDateFields.value.forEach((field: FieldDefinitionInterface) => {
        if (field.filterable && field.filterValue) {
            filters[field.key] = field.filterValue;
        }
    });

    let order = `order[${sortBy.value}]`;

    let requestObject: RequestObject = {
        page: page.value,
        itemsPerPage: itemsPerPage.value,
        [order]: sortDesc.value ? 'desc' : 'asc',
        ...filters,
    };

    return requestObject;
});

const openRequestedDateModal = (requestedDate: RequestedDateInterface): void => {
    errorMessage.value = '';
    selectedRequestedDate.value = requestedDate;
    requestedDateRequest.value = new RequestedDateRequest(requestedDate.status, requestedDate.fixedDate, requestedDate.serviceSite, requestedDate.fixedTime);
    requestedDateStore.getRequestedDate(router, requestObject.value, selectedRequestedDate.value.id);

    if (requestedDate.type === RequestedDateTypeEnum.SERVICE_REQUEST) {
        serviceRequestFormModalIsOpen.value = true;
    }
    if (requestedDate.type === RequestedDateTypeEnum.DAMAGE_EVENT) {
        damageEventFormModalIsOpen.value = true;
    }
};

const updateRequestedDate = () => {
    errorMessage.value = '';

    if (selectedRequestedDate.value === null) {
        errorMessage.value = trans('requested_date_not_selected', 'Requested date not selected', 'requested_dates');
        return;
    }

    if (requestedDateRequest.value.fixedDate && !isChecked.value) {
        requestedDateRequest.value.status = RequestedDateStatusEnum.FIXED;
    }

    if (isChecked.value) {
        requestedDateRequest.value.status = RequestedDateStatusEnum.CLOSED;
    }

    if (requestedDateRequest.value.status === selectedRequestedDate.value.status &&
        requestedDateRequest.value.fixedDate ===  selectedRequestedDate.value.fixedDate &&
        requestedDateRequest.value.serviceSite ===  selectedRequestedDate.value.serviceSite &&
        requestedDateRequest.value.fixedTime ===  selectedRequestedDate.value.fixedTime)
    {
        serviceRequestFormModalIsOpen.value = false;
        toastStore.addToast({
            message: trans('no_changes_were_made', 'No changes were made.', 'requested_dates'),
            type: ToastTypeEnum.Warning,
            timer: 5,
        });
        return;
    }

    requestedDateStore
        .updateRequestedDate(router, requestedDateRequest.value, selectedRequestedDate.value.id)
        .then(() => {
            serviceRequestFormModalIsOpen.value = false;
            page.value = 1;
            toastStore.addToast({
                message: trans(
                    'requested_date_updated_successfully',
                    'Requested date updated successfully!',
                    'requested_dates'
                ),
                type: ToastTypeEnum.Success,
                timer: 5,
            });
            requestedDateStore.getRequestedDates(router, requestObject.value).catch((error: ServerError) => {
                toastStore.addErrorToast(error.toString());
            });
        })
        .catch((error: ServerError) => {
            toastStore.addErrorToast(error.toString());
        });
};

const getThumbnailUrl = (imageId: number) => {
    return router.resolve({ name: 'get_booking_request_thumbnail', params: { id: imageId } }).href;
};

const getFullImageUrl = (imageId: number) => {
    return router.resolve({ name: 'get_booking_request_full_image', params: { id: imageId } }).href;
};

const downloadFullImage = (imageId: number) => {
    const link = document.createElement('a');
    link.href = getFullImageUrl(imageId);
    link.download = `image_${imageId}`;
    link.click();
};

watchEffect(() => {
    requestedDateStore.getRequestedDates(router, requestObject.value).catch((error: ServerError) => {
        toastStore.addErrorToast(error.toString());
    });
});
</script>
