import RequestedDates from '@/pages/requestedDates/RequestedDates.vue';

export default [
    {
        name: 'requested_dates',
        path: '/requested-dates',
        component: RequestedDates,
        meta: { requiresAuth: true, title: 'Requested Dates' },
    },
    {
        name: 'booking_requests',
        path: '/api/booking-requests',
        redirect: '',
        meta: { requiresAuth: true },
    },
    {
        name: 'get_booking_request',
        path: '/api/booking-requests/:id(\\d+)?',
        redirect: '',
        meta: { requiresAuth: true },
    },
    {
        name: 'booking_request_update',
        path: '/api/booking-requests/:id(\\d+)?',
        redirect: '',
        meta: { requiresAuth: true },
    },
    {
        name: 'get_booking_request_thumbnail',
        path: '/api/booking-request-images/:id(\\d+)?/thumbnail',
        redirect: '',
        meta: { requiresAuth: true },
    },
    {
        name: 'get_booking_request_full_image',
        path: '/api/booking-request-images/:id(\\d+)?/full-image',
        redirect: '',
        meta: { requiresAuth: true },
    },
];
